<template>
  <div style='position: relative;min-height: 835px;'>
    <div class="page">
      <!--    <div class="section section-1">-->
      <!--      <div class="section-title">-->
      <!--        {{ account_info.alias }}-->
      <!--      </div>-->
      <!--      <div class="section-flex">-->

      <!--        <div class="section-item">-->
      <!--          当前资产:{{ getFixed(account_info.init_asset + account_info.cumulative_revenue, 2) }}-->
      <!--        </div>-->
      <!--        <div class="section-item">-->
      <!--          累计收益:-->
      <!--          <span :style="{ color: getStockColor(account_info.cumulative_revenue)}">{{-->
      <!--              getFixed(account_info.cumulative_revenue, 2)-->
      <!--            }}</span>-->

      <!--        </div>-->
      <!--        <div class="section-item">-->
      <!--          收益率:-->
      <!--          <span :style="{ color: getStockColor(strategy_info.cumulative_revenue)}">{{-->
      <!--              getPrecent(account_info.init_asset ? account_info.cumulative_revenue / account_info.init_asset : 0, 2)-->
      <!--            }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->

      <div class="section section-2">
        <div class="section-title">
          {{ strategy_info.alias }}
        </div>
        <div class="section-flex">
          <!--        <div :style="{ color: getStockColor(strategy_info.earning_revenue)}" class="section-item ">-->
          <!--          当前收益: {{ getFixed(strategy_info.earning_revenue, 2) }}-->
          <!--        </div>-->
          <div :style="{ color: getStockColor(strategy_info.cur_rate)}" class="section-item">
            当年累计收益率: {{ getPrecent(strategy_info.year_rate, 2) }}
          </div>
          <div :style="{ color: getStockColor(strategy_info.earning_revenue)}" class="section-item">
            当前收益率:{{
              getPrecentWith0(strategy_info.earning_revenue, strategy_info.current_equity, 2)
            }}
          </div>

          <div class="section-item section-pop">
            <!--            <span>每{{ strategy_info.periods === 'W' ? '周' : '天' }}选取{{ strategy_info.stock_num }}只股票</span>-->
            <span>每次选取{{ strategy_info.stock_num }}只股票</span>
            <!--            <span>-->
            <!--              每次选取{{ strategy_info.stock_strategy_type === '轮动' ?  strategy_info.select_strategy_num  :  strategy_info.stock_num  }}只-->
            <!--              {{ strategy_info.stock_strategy_type === '轮动' ? '策略' : '股票' }}-->
            <!--            </span>-->
          </div>
        </div>
      </div>

      <div>
        <div class="section-bg">
          <div class="title">
            <div style="position: relative;height: 25px">
              <div style="position: absolute;left: 0px;bottom: 0px">最新选股结果</div>
              <div v-if="strategy_info.stock_type == '轮动' && stock_list.length!=0"
                   style="position: absolute;right: 0px;bottom: 0px;font-size: 13px">
                仓位占比：{{ getFixed(stock_list[0].pos_ratio * 100, 2) }}%
              </div>
            </div>
          </div>
          <div v-if="showing1">
            <div class="stock-list">
              <div v-for="(item,index) in stock_list" :key="item.stock_code" class="stock-item"
                   @click="selectStock(index)">
            <span :style="{ backgroundColor: getStockColor(item.earning_rate)}"> {{
                item.stock_name + '(' + item.stock_code + ')'
              }} {{ getPrecent(item.earning_rate, 2) }}</span>
              </div>

            </div>
            <div class="stock-foot section-flex">
              <div class="section-item">选股时间:{{ select_stock.choice_time }}</div>
              <div class="section-item">卖出时间: {{ select_stock.sell_time }}</div>
            </div>
          </div>
          <div v-else style="text-align: center;margin-top: 5%">暂无数据</div>
        </div>
      </div>
      <div>
        <div class="section-bg">
          <div class="title" style="display: flex">
            历史选股结果
            <div class="lab-btn" style="text-align: right;flex: 1;color:#4b74ab" @click="handlerGoHistory(item)">
              查看往年情况
            </div>
          </div>

          <div class="table" v-if="showing2">
            <div class="row header">
              <div class="col  ">选股时间</div>
              <div class="col  col-3">选股</div>
              <!--            <div class="col  ">收益</div>-->
              <div class="col">收益率</div>
            </div>
            <div v-for="item in history_list" v-bind:key="item.stock_code" class="row "
                 style="border-bottom: 1px solid rgba(	119,136,153,0.3);">
              <div class="col " style="">
                <div>
                  <div class="content" style="flex: 1">{{ item.time_key }}</div>
                  <div class="content" style="flex: 1;font-size: 10px">
                    <div v-if="strategy_info.stock_type == '轮动'">仓位 {{
                        getFixed(item.stocks[0].pos_ratio * 100, 2)
                      }}%
                    </div>
                    <div v-else-if="strategy_info.stock_type == '事件'">{{ item.stocks[0].money_num }}# 资金</div>
                  </div>
                </div>


              </div>
              <div class="col col-3">
                <div class="content">
                  <div v-for="citem in item.stocks" :key="citem.stock_name" class="">
                    {{ citem.stock_name + '(' + citem.stock_code + ')' }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="content">
                  <div v-for="citem in item.earning" :key="citem.earning_revenue">
                    <!--                <div :style="{ color: getStockColor(citem.earning_revenue)}" class="col_new">{{ getFixed(citem.earning_revenue, 2) }}</div>-->
                    <div :style="{ color: getStockColor(citem.earning_rate)}" class="col_new">
                      {{ getPrecent(citem.earning_rate, 2) }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-else style="text-align: center;margin-top: 5%">暂无数据</div>
        </div>

      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;background-color: black;"></VersionNum>
  </div>

</template>

<script>
import {getStockDetail} from '@/api/stock'
import InfoCommen from "../../mixins/InfoCommen";
import VersionNum from "../com/version_num";

export default {
  name: "stock-strategy-info",
  components: {
    VersionNum
  },
  data() {
    return {
      showing1: true,
      showing2: true,
      strategy_id: '',
      account_info: {},
      strategy_info: {},
      stock_info: [],
      stock_list: [],
      select_stock: {},
      history_list: [],
      account_id: ''
    }
  },
  mixins: [InfoCommen],
  mounted() {
    this.account_id = this.$route.query.stock_account_id
    this.strategy_id = this.$route.query.stock_strategy_id
    // console.log(this.strategy_id)
    this.handlerGetDetail()
  },
  methods: {

    handlerGoHistory() {
      this.$router.push({
        path: "/stock/detail/more_history", query: {
          account_id: this.account_id,
          stock_strategy_id: this.strategy_id,
          stock_strategy_name: this.strategy_info.alias,
          year: "2023"
        }
      });
    },

    //格式化数据
    getArr(source) {
      const arr = []
      const keys = Object.keys(source);
      keys.map(key => {
        const items = source[key]
        for (let i = 0; i < items.length; i++) {
          arr.push({
            ...items[i],
            time_key: key,
            id: new Date().getTime()
          })
        }
      })
      return arr;
    },

    selectStock(index) {
      this.select_stock = this.stock_list[index]
    },
    //获取数据
    handlerGetDetail() {
      getStockDetail(this.strategy_id).then(res => {
        const {account_info, strategy_info, stock_info} = res.data;
        if (JSON.stringify(stock_info.currency) === "{}") {
          this.showing1 = false
        }
        if (JSON.stringify(stock_info.history) === "{}") {
          this.showing2 = false
        }
        this.account_info = res.data.account_info;
        this.account_info.cur_asset = account_info.earning_revenue + account_info.current_equity
        this.account_info.earning_revenue_rate = ((account_info.earning_revenue + account_info.current_equity) / account_info.init_asset) - 1;
        this.strategy_info = strategy_info;
        this.strategy_info.earning_revenue_rate = ((strategy_info.earning_revenue + strategy_info.current_equity) / strategy_info.init_asset) - 1;
        this.stock_list = this.getArr(stock_info.currency)
        // console.log(this.strategy_info)


        for (let i = 0; i < this.stock_list.length - 1; ++i) {
          let indexMax = i;
          //遍历剩余长度找到最小下标
          for (let j = i; j < this.stock_list.length; ++j) {
            if (Number(this.stock_list[j].choice_time) > Number(this.stock_list[indexMax].choice_time)) {
              indexMax = j;
            }
          }
          if (indexMax !== i) {
            const temp = this.stock_list[i];
            this.stock_list[i] = this.stock_list[indexMax];
            this.stock_list[indexMax] = temp;
          }
        }
        // console.log(this.stock_list)

        this.selectStock(0)

        // this.history_list = this.getArr(stock_info.history)
        const arr = [];

        Object.keys(stock_info.history).forEach(key => {
          const list = stock_info.history[key];
          const stocks = list.map(item => {
            return {
              stock_name: item.stock_name,
              stock_code: item.stock_code,
              pos_ratio: item.pos_ratio,
              money_num: item.money_num
            }
          })
          const earning = list.map(item => {
            return {
              earning_revenue: item.earning_revenue,
              earning_rate: item.earning_rate
            }
          })
          arr.push({
            time_key: key,
            stocks: stocks,
            earning: earning,
            id: new Date().getTime(),
          })
        })
        // console.log(arr)
        for (let i = 0; i < arr.length.length - 1; ++i) {
          let indexMax = i;
          //遍历剩余长度找到最小下标
          for (let j = i; j < arr.length.length; ++j) {
            if (Number(arr[j].time_key) > Number(arr[indexMax].time_key)) {
              indexMax = j;
            }
          }
          if (indexMax !== i) {
            const temp = arr[i];
            arr[i] = arr[indexMax];
            arr[indexMax] = temp;
          }
        }
        //历史数据展示由近到远
        this.history_list = arr.reverse()
        console.log(this.history_list)
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === "/stock") {
      next(vm => {
        vm.newPath = this.account_id
      })
    }
  }
}
</script>

<style lang="less" scoped>
.section-title {
  font-size: 1rem;
}

.section-bg {
  background-color: rgba(38, 38, 47, 0.9);
  font-size: 0.9rem;
  margin: 10px;
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;

  .title {
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    border-bottom: 3px solid #ffffff;
  }

  .stock-list {
    margin: 5px 0;

    .stock-item {
      padding: 5px;

      span {
        padding: 6px 16px;
        border-radius: 8px;
        display: inline-block;
      }
    }
  }
}


.section-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section {
  font-size: 0.9rem;
  margin: 10px;
  padding: 10px 0;
  padding-bottom: 15px;
  border-bottom: solid 0.1px #383838;

  .section-item {
    padding-top: 8px;
    text-align: left;
  }

}

.section-1 {
  .section-item {
    width: 50%;
  }
}

.section-2 {
  .section-item {
    width: 50%;
  }

  .section-pop {
    margin-top: 5px;

    span {
      padding: 8px 16px;
      background-color: rgba(38, 38, 47, 0.9);
      border-radius: 8px;
    }
  }
}


.table {
  text-align: center;
  line-height: 2rem;

  .row {
    display: flex;
    margin: 10px 5px;

    .col {
      width: 25%;
      overflow: hidden;
      flex: 1;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
      }
    }

    .col_new {
      flex: 1;
    }


    .col-3 {
      flex: 3;
    }


    .label {
      padding: 3px 10px;
    }
  }

  .header {
    color: #4b74ab;
    font-size: 0.8rem;

  }
}

</style>
